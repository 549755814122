<template>
    <el-dialog title="新增banner图" :visible="visible" @close="closeDialog" :close-on-click-modal="false">
        <el-form :model="addForm" ref="addForm" label-width="100px" :rules="rules">
            <el-form-item label="场景" prop="scene">
                <el-select v-model="addForm.scene" placeholder="场景" clearable>
                    <el-option key="首页" label="首页" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item required label="banner图" prop="bannerUrl" ref="bannerUrlUpload">
                <el-upload
                    :class="{ hide: hideUpload }"
                    accept="image/jpeg,image/png,image/jpg"
                    :on-remove="handleRemove"
                    :on-success="successUpload"
                    :file-list="fileList"
                    :limit="limitCount"
                    :action="resources_api_host + '/file/upload'"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="onBeforeUpload"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item required label="跳转页面" prop="linkUrlState">
                <el-radio-group v-model="addForm.linkUrlState">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="跳转链接" prop="linkUrl" v-if="addForm.linkUrlState == 1">
                <el-input class="linkUrlInput" v-model="addForm.linkUrl" placeholder="请输入跳转链接"></el-input>
            </el-form-item>
            <el-form-item label="显示状态" prop="state">
                <el-radio-group v-model="addForm.state">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        <Vviewer ref="viewer"></Vviewer>
    </el-dialog>
</template>
<script>
import Vviewer from "@/components/Vviewer";
import { bannerAdd, bannerUpdate, bannerQuery } from "@/api/managementCenter";
export default {
    components: { Vviewer },
    props: ["visible", "flowId"],
    data() {
        const _this = this;
        const checkBannerUrl = (rule, value, callback) => {
            if (_this.fileList.length == 0) {
                return callback(new Error("请上传图片"));
            } else {
                callback();
            }
        };
        return {
            hideUpload: false,
            limitCount: 1,
            addForm: {
                scene: 1,
                linkUrlState: 0,
                linkUrl: "",
                state: 1,
                bannerUrl: [],
            },
            rules: {
                scene: [{ required: true, message: "请选择场景", trigger: "change" }],
                linkUrl: [{ required: true, message: "请输入跳转链接", trigger: "change" }],
                state: [{ required: true, message: "请设置显示状态", trigger: "change" }],
                linkUrlState: [{ required: true, message: "请设置是否跳转页面", trigger: "change" }],
                bannerUrl: [{ validator: checkBannerUrl }],
            },
            fileList: [],
            resources_api_host: process.env.VUE_APP_RESOURCES_API_HOST,
            resources_host: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    watch: {
        visible(data) {
            if (data && this.flowId != "add") {
                this.getInfo();
            }
        },
    },
    methods: {
        getInfo() {
            const _this = this;
            const params = {
                flowId: this.flowId,
            };
            bannerQuery(params)
                .then((res) => {
                    _this.addForm = {
                        scene: res.data.scene,
                        linkUrlState: res.data.linkUrlState,
                        linkUrl: "",
                        state: res.data.state,
                        bannerUrl: [
                            {
                                url: _this.resources_host + res.data.pictureUrl,
                                response: {
                                    data: {
                                        filePath: res.data.pictureUrl,
                                        coverImgUrl: res.data.thumbnailUrl,
                                    },
                                },
                            },
                        ],
                    };
                    if (res.data.linkUrlState == 1) {
                        _this.addForm.linkUrl = res.data.linkUrl;
                    }
                    _this.fileList = [
                        {
                            url: _this.resources_host + res.data.pictureUrl,
                            response: {
                                data: {
                                    filePath: res.data.pictureUrl,
                                    coverImgUrl: res.data.thumbnailUrl,
                                },
                            },
                        },
                    ];
                    this.hideUpload = this.fileList.length >= this.limitCount;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        successUpload(res, file, fileList) {
            if (res.rescode == 200) {
                this.fileList = fileList;
                this.addForm.bannerUrl = fileList;
                this.$refs.bannerUrlUpload.clearValidate();
                this.hideUpload = this.fileList.length >= this.limitCount;
            }
        },
        onBeforeUpload(file) {
            const isIMAGE = file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png";
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isIMAGE) {
                this.$message.error("上传文件只能是图片格式（jpg/jpeg/png）!");
                return false;
            }
            if (!isLt5M) {
                this.$message.error("上传文件大小不能超过 5MB!");
                return false;
            }
            return isIMAGE && isLt5M;
        },
        handlePictureCardPreview(file) {
            const _this = this;
            this.$refs.viewer.show([
                {
                    thumbnail: _this.resources_host + file.response.data.coverImgUrl,
                    source: _this.resources_host + file.response.data.filePath,
                },
            ]);
        },
        handleRemove(file, fileList) {
            this.addForm.bannerUrl = fileList;
            this.fileList = fileList;
            this.hideUpload = this.fileList.length >= this.limitCount;
        },
        confirm() {
            const _this = this;
            _this.$refs.addForm.validate((valid) => {
                if (valid) {
                    let params = {
                        linkUrl: this.addForm.linkUrl,
                        linkUrlState: this.addForm.linkUrlState,
                        scene: this.addForm.scene,
                        state: this.addForm.state,
                        pictureUrl: this.addForm.bannerUrl[0].response.data.filePath,
                        thumbnailUrl: this.addForm.bannerUrl[0].response.data.coverImgUrl,
                    };
                    const loading = _this.$loading({
                        lock: true,
                        text: "提交中,请稍后...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                        customClass: "fullScreenLoading",
                    });
                    if (_this.flowId != "add") {
                        params.flowId = _this.flowId;
                        bannerUpdate(params)
                            .then(() => {
                                loading.close();
                                _this.closeDialog(true);
                            })
                            .catch((err) => {
                                console.log(err);
                                loading.close();
                            });
                    } else {
                        const loading = _this.$loading({
                            lock: true,
                            text: "提交中,请稍后...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                            customClass: "fullScreenLoading",
                        });
                        bannerAdd(params)
                            .then((res) => {
                                loading.close();
                                _this.closeDialog(true);
                            })
                            .catch((err) => {
                                console.log(err);
                                loading.close();
                            });
                    }
                } else {
                    console.log("error submit!!");
                }
            });
        },
        closeDialog(flag) {
            this.$refs.addForm.resetFields();
            this.addForm.linkUrl = "";
            this.fileList = [];
            this.hideUpload = false;
            this.$emit("hideDialog", flag);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .hide .el-upload--picture-card {
    display: none;
}
.linkUrlInput {
    width: 300px;
}
</style>
