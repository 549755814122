<template>
    <div class="app-container">
        <el-row>
            <el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
                <el-form-item prop="scene">
                    <el-select clearable v-model="queryForm.scene" placeholder="请选择场景">
                        <el-option label="首页" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="state">
                    <el-select clearable v-model="queryForm.state" placeholder="请选择显示状态">
                        <el-option label="显示" value="1"></el-option>
                        <el-option label="隐藏" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="dataRange">
                    <el-date-picker clearable v-model="queryForm.dataRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item class="floatRight">
                    <el-dropdown split-button type="primary" @click="queryData" @command="handleCommand">
                        查询
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reset">重置查询</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="marginBottom-8">
            <el-button type="primary" @click="addBanner">新增轮播图</el-button>
        </el-row>
        <el-table v-loading="listLoading" element-loading-text="加载中" :data="list" border fit highlight-current-row>
            <el-table-column label="编号" :show-overflow-tooltip="true" align="center">
                <template slot-scope="scope">
                    {{ scope.row.flowId }}
                </template>
            </el-table-column>
            <el-table-column label="场景" align="center">
                <template slot-scope="scope">
                    {{ state.bannerScene[scope.row.scene] }}
                </template>
            </el-table-column>
            <el-table-column label="图片" align="center">
                <template slot-scope="scope">
                    <img @click="priviewImg(scope.row.thumbnailUrl, scope.row.pictureUrl)" class="img" :src="resources_host + scope.row.thumbnailUrl" />
                </template>
            </el-table-column>
            <el-table-column label="是否跳转" align="center">
                <template slot-scope="scope">
                    {{ state.bannerLinkUrlState[scope.row.linkUrlState] }}
                </template>
            </el-table-column>
            <el-table-column label="跳转地址" align="center">
                <template slot-scope="scope">
                    {{ scope.row.linkUrlState == 1 ? scope.row.linkUrl : "--" }}
                </template>
            </el-table-column>
            <el-table-column label="显示状态" align="center">
                <template slot-scope="scope">
                    {{ state.bannerState[scope.row.state] }}
                </template>
            </el-table-column>
            <el-table-column label="上传时间" align="center">
                <template slot-scope="scope">
                    <span>{{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-popconfirm :title="scope.row.state == 1 ? '确认隐藏该banner图么？' : scope.row.state == 2 ? '确定显示该banner图么？' : ''" @confirm="changeState(scope.row.flowId, scope.row.state)">
                        <el-button slot="reference" type="text" size="small">{{ scope.row.state == 1 ? "隐藏" : scope.row.state == 2 ? "显示" : "" }}</el-button>
                    </el-popconfirm>
                    <el-button class="marginLeft-8 marginRight-8" type="text" size="small" @click="edit(scope.row.flowId)">修改</el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.flowId)">
                        <el-button slot="reference" type="text" size="small">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <AddBabber :visible="addBannerVisible" :flowId="op" @hideDialog="hideDialog"></AddBabber>
        <Vviewer ref="viewer"></Vviewer>
    </div>
</template>
<script>
import Vviewer from "@/components/Vviewer";
import { mapGetters } from "vuex";
import { getBannerPageList, bannerUpdate, bannerDelete } from "@/api/managementCenter";
import state from "@/utils/state";
import moment from "moment";
import AddBabber from "./components/addBanner";

export default {
    components: { AddBabber, Vviewer },
    data() {
        return {
            listLoading: true,
            list: [],
            moment,
            addBannerVisible: false,
            op: "add",
            queryForm: {
                state: "",
                scene: "",
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
            taskState: "1",
            resources_host: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    computed: {
        state() {
            return state.state;
        },
        ...mapGetters(["userInfo"]),
    },
    created() {
        this.fetchData();
    },
    methods: {
        priviewImg(thumbnailUrl, pictureUrl) {
            const _this = this;
            this.$refs.viewer.show([
                {
                    thumbnail: _this.resources_host + thumbnailUrl,
                    source: _this.resources_host + pictureUrl,
                },
            ]);
        },
        del(flowId) {
            let params = {
                flowId: flowId,
            };
            const _this = this;
            const loading = _this.$loading({
                lock: true,
                text: "提交中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            bannerDelete(params)
                .then(() => {
                    loading.close();
                    _this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    _this.hideDialog(true);
                })
                .catch((err) => {
                    console.log(err);
                    loading.close();
                });
        },
        handleClick(e) {
            this.fetchData();
        },
        changeState(flowId, state) {
            let params = {
                flowId: flowId,
                state: state == 1 ? 2 : 1,
            };
            const _this = this;
            const loading = _this.$loading({
                lock: true,
                text: "提交中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            bannerUpdate(params)
                .then(() => {
                    loading.close();
                    _this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    _this.hideDialog(true);
                })
                .catch((err) => {
                    console.log(err);
                    loading.close();
                });
        },
        hideDialog(refeshFlag) {
            this.addBannerVisible = false;
            this.op = "add";
            if (refeshFlag == true) {
                this.fetchData();
            }
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.fetchData();
        },
        edit(flowId) {
            this.addBannerVisible = true;
            this.op = flowId;
        },
        addBanner() {
            this.addBannerVisible = true;
        },
        handleCommand(type) {
            if (type == "reset") {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        queryData() {
            this.fetchData();
        },
        fetchData() {
            this.listLoading = true;
            const queryForm = this.queryForm;
            let params = {
                pageNum: queryForm.pageNum,
                pageSize: queryForm.pageSize,
            };
            if (queryForm.scene && queryForm.scene != "") {
                params.scene = queryForm.scene;
            }
            if (queryForm.state && queryForm.state != "") {
                params.state = queryForm.state;
            }
            if (queryForm.dataRange && queryForm.dataRange != "") {
                params.maxAddTime = moment(queryForm.dataRange[1]).format("YYYY-MM-DD") + " 23:59:59";
                params.minAddTime = moment(queryForm.dataRange[0]).format("YYYY-MM-DD") + " 00:00:00";
            }
            const _this = this;
            getBannerPageList(params)
                .then((response) => {
                    _this.list = response.data.dataList;
                    _this.queryForm.totalCount = response.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
    },
};
</script>
<style lang="less" scoped>
.img {
    width: 100px;
    height: 80px;
    cursor: pointer;
}
</style>
